<template>
  <div class="settlement-page">
    <header class="page-header" style="margin-left:2%;">
      <div style="margin-bottom:2%; display:flex; wrap-text:wrap; justify-content:space-between;">
        <title-item title="Тооцоо нийлэх"/>
        <el-select
          v-model="currentOutletId"
          placeholder="Харилцагч сонгох"
          style="width:200px;"
          @change="changeCurrentOutlet(currentOutletId)"
        >
          <el-option
            v-for="item in $root.chosenOutlets"
            :key="item._id"
            :label="item.full_name_eng"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex flex-row gap-2.5">
        <el-card v-if="currentInvoice !== null && currentInvoice !== undefined" class="cursor-pointer rounded-xl" >
          <div @click="handleClick">
            <span v-if="currentInvoice && currentInvoice.left_day >= 0">Систем ашиглалтын төлбөр төлөхөд <br/> <strong :style="currentInvoice.left_day >= 0 ? 'color: red; font-size:16px;' : 'color:black font-size:16px;'">{{currentInvoice.left_day}}</strong> хоног үлдсэн байна.</span>
            <span v-else-if="currentInvoice && currentInvoice.left_day < 0">Систем ашиглалтын төлбөр төлөх хугацаа <br/> <strong style="color: red; font-size:16px;">{{currentInvoice.left_day}}</strong> хоног хэтэрсэн байна.</span>
            <el-row style="margin-top: 10px; font-size:16px;">
              <strong v-if="currentInvoice && currentInvoice.pay_total">Т: {{$helpers.formatPrice(currentInvoice.pay_total)}}₮</strong>
            </el-row>
          </div>
        </el-card>
        <el-card style="border-radius: 12px; width: 240px">
          <span style="color:#808080; font-size:12px;">Тооцоо тасалбар болгох хугацаа</span>
          <el-row style="margin-top: 10px; font-size:16px;">
            <strong>Сар бүрийн</strong><br/>
            <b>01-15, 15-31/30</b>
          </el-row>
        </el-card>
        <el-card v-if="predictSettlementData" style="border-radius: 12px; width: 495px;">
          <span style="color:#808080; font-size:12px;">Нийт борлуулалт</span>
          <el-row style="margin-top: 10px; font-size:16px;">
            <strong class="text-xl" style="color: #1B74E4;">{{ predictSettlementData.total_sales_formatted }}</strong>
          </el-row>
        </el-card>
        <el-card v-if="predictSettlementData" style="border-radius: 12px; width: 240px;">
          <span style="color:#808080; font-size:12px;">Нийт захиалга</span>
          <el-row style="margin-top: 10px; font-size:16px;">
            <strong class="text-xl" style="color: #1B74E4;">{{ predictSettlementData.sum_total }}</strong>
          </el-row>
        </el-card>
        <el-card v-if="predictSettlementData" style="border-top-left-radius: 12px; border-bottom-left-radius: 12px; width: 240px;">
          <span style="color:#808080; font-size:12px;">Регистерийн дугаар</span>
          <el-row style="margin-top: 10px; font-size:16px;">
            <strong>{{ $root.isAdmin ? $root.chosenOutlets[0].vat_regno : $root.outlet.vat_regno }}</strong>
          </el-row>
        </el-card>
        <el-card v-if="predictSettlementData" style="width: 240px;">
          <span style="color:#808080; font-size:12px;">Дансны нэр</span>
          <el-row style="margin-top: 10px; font-size:16px;">
            <strong>{{ $root.isAdmin ? $root.chosenOutlets[0].bank_account_name : $root.outlet.bank_account_name }}</strong>
          </el-row>
          <span style="color:#808080; font-size:12px;">Дансны дугаар</span>
          <el-row style="margin-top: 10px; font-size:16px;">
            <strong>{{ $root.isAdmin ? $root.chosenOutlets[0].bank_account_number : $root.outlet.bank_account_number }}</strong>
          </el-row>
        </el-card>
        <el-card v-if="predictSettlementData" style="border-top-right-radius: 12px; border-bottom-right-radius: 12px; width: 260px;">
          <span style="color:#808080; font-size:12px;">SMS тоо (Шинэ захиалга)</span>
          <el-row style="margin-top: 10px; font-size:16px;">
            <strong>{{ predictSettlementData.sms_count }}</strong>
          </el-row>
        </el-card>
      </div>
    </header>
    <div v-if="predictSettlementData" style="padding-bottom: 1%; margin-left:2%; display:flex; flex-wrap:wrap;" v-loading="predictLoading">
      <el-card style="border-radius: 12px; width: 240px; background-color:#F8F8F8; margin-right:10px;">
        <div class="text-secondary mb10" style="font-weight: bold; font-size:14px;">Тооцоо нийлэх хугацаа</div>
        <strong class="text-xl">
          {{
            $helpers.formatDate(predictSettlementData.start_date, "MM/DD")
          }}
          -
          {{ $helpers.formatDate(predictSettlementData.end_date, "MM/DD") }}
        </strong>
      </el-card>
      <el-card style="border-radius: 12px; margin-right:10px; width: 240px; background-color:#F8F8F8;">
        <div class="text-secondary mb10" style="font-weight: bold; font-size:14px;">Захиалга</div>
        <strong class="text-xl">
          {{
            $helpers.formatPrice(
              parseInt(
                predictSettlementData.all_order_count_by_last_settlement
                  ? predictSettlementData.all_order_count_by_last_settlement
                  : 0
              )
            )
          }}
        </strong>
      </el-card>
      <el-card style="border-radius: 12px; margin-right:10px; width: 240px; background-color:#F8F8F8;">
        <div class="text-secondary mb10" style="font-weight: bold; font-size:14px;">Борлуулалт</div>
        <strong class="text-xl">
          {{
            $helpers.formatPrice(
              parseInt(
                predictSettlementData.total_sales_by_last_settlement
                  ? predictSettlementData.total_sales_by_last_settlement
                  : 0
              )
            )
          }}₮
        </strong>
      </el-card>
      <el-card style="border-radius: 12px; margin-right:10px; width: 240px; background-color:#F8F8F8;">
        <div class="text-secondary mb10" style="font-weight: bold; font-size:14px;">Цуцлалтын тоо</div>
        <strong class="text-xl">
          {{
            $helpers.formatPrice(
              parseInt(
                predictSettlementData.canceled_order_count
                  ? predictSettlementData.canceled_order_count
                  : 0
              )
            )
          }}
          <span v-if="predictSettlementData.canceled_order_count > 0"> * 3000</span>
        </strong>
      </el-card>
      <el-card style="border-radius: 12px; margin-right:10px; width: 240px; background-color:#F8F8F8;">
        <div class="text-secondary mb10" style="font-weight: bold; font-size:14px;">“Амалсан цагтаа” амжаагүй захиалга</div>
        <strong class="text-xl" style="color:#F24E1E;">
          {{
            $helpers.formatPrice(
              parseInt(
                predictSettlementData.late_prepare_order_count
                  ? predictSettlementData.late_prepare_order_count
                  : 0
              )
            )
          }} * 3,000
        </strong>
      </el-card>
      <el-card style="border-radius: 12px; width: 240px; background-color:#F8F8F8;">
        <div class="text-secondary mb10" style="font-weight: bold; font-size:14px;">Таны тооцоо <br/> нийлэх өдөр</div>
        <strong class="text-xl">{{
          predictSettlementData.remain_date
        }}</strong>
        хоног үлдсэн байна
      </el-card>
    </div>
    <div class="panel" style="margin-left:2%; width:95%;" v-loading="loader">
      <div class="panel-item" style="display:flex; justify-content:space-between;">
        <header>Одоогийн тооцоо</header>
        <div style="flex-wrap:wrap;" v-if="oneTableData">
          <el-button
            @click="dialogVisibleBuulgah = true"
            :disabled="!oneTableData.is_withdraw_possible"
            type="primary"
            size="mini"
          >
            Буулгах
          </el-button>
          <el-button
            :disabled="!oneTableData.has_action"
            type="success"
            @click="confirm(oneTableData, 20)"
            size="mini"
            >Зөвшөөрөх
          </el-button>
          <el-button
            :disabled="!oneTableData.has_action"
            @click="unconfirm(oneTableData)"
            type="danger"
            size="mini"
          >
            Зөвшөөрөхгүй
          </el-button>
        </div>
      </div>
      <div style="display:flex; flex-direction:column;" v-if="oneTableData">
        <div style="margin-top:1%; margin-left:1%; display:flex; flex-wrap: wrap">
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Байгууллагын нэр</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{oneTableData.partner_name}}</span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Огноо</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>
                {{ $helpers.formatDate(oneTableData.start_date, "MM/DD") }} -
                {{ $helpers.formatDate(oneTableData.end_date, "MM/DD") }}
              </span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Захиалга</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ oneTableData.order_count }}</span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Меню дүн</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ $helpers.formatPrice(parseInt(oneTableData.total_sales)) }}₮</span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Ток Ток шимтгэл</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ $helpers.formatPrice(parseInt(oneTableData.net_sales)) }}₮</span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Цуцлалтын шимтгэл</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ oneTableData.order_canceled_amount }}</span>
            </div>
          </div>
          <div style="display:flex; height:100%; width: 30%; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Тайлбар(гараар оруулсан)</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <p style=" overflow: auto; height: 50px;">
                {{ !['', 'null', undefined, null].includes(oneTableData.settlement_note) ? oneTableData.settlement_note : '-' }}
              </p>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Хүргэлтийн төлбөр</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ oneTableData.delivery_charge_addition }}</span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Амалсан цагтаа амжаагүй</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ oneTableData.outlet_delayed_order_count }}</span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Буцаалтын дүн</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ $helpers.formatPrice(parseInt(oneTableData.withdraw_amount_calculated === null ? 0 : oneTableData.withdraw_amount_calculated)) }}₮</span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Зөрүү дүн</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ $helpers.formatPrice(parseInt(oneTableData.zoruu_dun === null ? 0 : oneTableData.zoruu_dun)) }}₮</span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Нөатын дүн</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ oneTableData.noat_value }}</span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Нөатын төлөв</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ oneTableData.is_noat_value_typed }}</span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>SMS Тоо</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ oneTableData.sms_count }}</span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Тайлбар</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <el-button v-if="oneTableData.is_declined_by_client === true" type="primary" size="mini" @click="getPartnerSettlementDescription(oneTableData)">Тайлбар</el-button>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Үлдэгдэл илгээсэн огноо</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ oneTableData.balance_sent_date }}</span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Өмнөх тооцооны үлдэгдэл</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ $helpers.formatPrice(parseInt(oneTableData.previous_amount === null ? 0 : oneTableData.previous_amount)) }}₮</span>
            </div>
          </div>
          <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
            <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
              <span>Нийт буулгах дүн</span>
            </div>
            <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
              <span>{{ $helpers.formatPrice(parseInt(oneTableData.withdraw_amount_calculated === null ? 0 : oneTableData.withdraw_amount_calculated)) }}₮</span>
            </div>
          </div>
        </div>
        <div @click="detailSettlement" style="margin-top:1%; margin-left: 1%; margin-right:2%; background-color:#F8F8F8; height: 32px; width:97%; border-radius:5px; display:flex; justify-content:center; align-items:center;">
          <i :class="isDetail ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
        </div>
        <div v-if="isDetail" style="margin-left:1%; margin-right:2%; margin-top:1%;">
          <el-table
            :data="daysData"
            style="width: 100%; font-size:1.2vh;"
            v-loading="dropdownLoading"
            @row-click="showDayDetail"
            height="350px"
            border
          >
            <el-table-column label="Огноо">
              <template slot-scope="prop">
                <span style="font-size:1.4vh;">{{ $helpers.formatDate(prop.row.date, "MM/DD") }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Захиалга" prop="order_count">
              <template slot-scope="prop">
                <span style="font-size:1.4vh;">{{prop.row.order_count}}</span>
              </template>
            </el-table-column>
            <el-table-column label="Меню дүн">
              <template slot-scope="prop">
                <span style="font-size:1.4vh;">{{ $helpers.formatPrice(parseInt(prop.row.total_sales)) }}₮</span>
              </template>
            </el-table-column>
            <el-table-column label="Шимтгэл">
              <template slot-scope="prop">
                <span style="font-size:1.4vh;">{{ $helpers.formatPrice(parseInt(prop.row.net_sales)) }}₮</span>
              </template>
            </el-table-column>
            <el-table-column label="Зөрүүтэй">
              <template slot-scope="prop">
                <span style="font-size:1.4vh; color:red;" v-if="prop.row.isZoruu">Зөрүүтэй</span>
              </template>
            </el-table-column>
            <el-table-column label="Төлөв" width="120px">
              <template
                slot-scope="prop"
                v-if="prop.row.is_canceled === true"
              >
                <span style="font-size:16px; color:red;">
                  Цуцалсан({{
                    prop.row.canceled_count
                  }})
                </span>
              </template>
            </el-table-column>
          </el-table>
          <!-- <table>
            <thead>
              <tr>
                <th>Огноо</th>
                <th>Захиалга</th>
                <th>Меню дүн</th>
                <th>Шимтгэл</th>
                <th>Зөрүүтэй</th>
                <th>Төлөв</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dayData, dayDataIndex) in oneTableData.days" :key="dayDataIndex">
                <td>{{ $helpers.formatDate(dayData.date, "MM/DD") }}</td>
                <td>{{ dayData.order_count }}</td>
                <td>{{ $helpers.formatPrice(parseInt(dayData.total_sales)) }}₮</td>
                <td>{{ $helpers.formatPrice(parseInt(dayData.net_sales)) }}₮</td>
                <td>
                  <span v-if="dayData.isZoruu" style="color:red; ">
                    Зөрүүтэй
                  </span>
                </td>
                <td>
                  <span v-if="dayData.is_canceled" style="color:red; ">
                    Цуцалсан({{
                      dayData.canceled_count
                    }})
                  </span>
                </td>
              </tr>
            </tbody>
          </table> -->
        </div>
      </div>
    </div>
    <div class="panel" v-loading="settlementLoading" style="margin-left:2%; width:95%;">
      <div class="panel-item">
        <header>Тооцооны түүх</header>
      </div>
      <div v-for="(settlement, settlementIndex) in settlementData" :key="settlementIndex" style="margin-top:1%; margin-left:1%; display:flex; flex-wrap: wrap">
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Төлөв</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>{{settlement.status}}</span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Байгууллагын нэр</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>{{settlement.partner_name}}</span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Огноо</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ $helpers.formatDate(settlement.start_date, "MM/DD") }} -
            {{ $helpers.formatDate(settlement.end_date, "MM/DD") }}
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Захиалга</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ settlement.order_count}}
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Меню дүн</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ $helpers.formatPrice(parseInt(settlement.total_sales)) }}₮
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Ток Ток шимтгэл</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ $helpers.formatPrice(parseInt(settlement.net_sales)) }}₮
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Өмнөх тооцооны үлдэгдэл</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ $helpers.formatPrice(parseInt(settlement.previous_amount === null ? 0 : settlement.previous_amount)) }}₮
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Нийт буулгасан тооцоолсон</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ $helpers.formatPrice(parseInt(settlement.withdraw_amount_calculated)) }}₮
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Цуцлалтын шимтгэл</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ $helpers.formatPrice(settlement.order_canceled_amount === null ? 0 : settlement.order_canceled_amount) }}
            </span>
          </div>
        </div>
        <div style="display:flex; height:100%; width: 39%; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Тайлбар(гараар оруулсан)</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <p style=" overflow: auto; height: 50px;">
              {{ !['', 'null', undefined, null].includes(settlement.settlement_note) ? settlement.settlement_note : '-' }}
            </p></div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Амалсан цагтаа амжаагүй</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{settlement.outlet_delayed_order_count}}
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Буцаалтын дүн</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ $helpers.formatPrice(parseInt(settlement.withdraw_amount_calculated === null ? 0 : settlement.withdraw_amount_calculated)) }}₮
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Зөрүү дүн</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ $helpers.formatPrice(parseInt(settlement.zoruu_dun === null ? 0 : settlement.zoruu_dun)) }}₮
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Нөатын дүн</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ settlement.noat_value }}₮
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Нөатын төлөв</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ settlement.is_noat_value_typed }}
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Үлдэгдэл илгээсэн огноо</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ settlement.balance_sent_date }}
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Регистерийн дугаар</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ settlement.vat_regno }}
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Банкны нэр</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ settlement.bank_account_name }}
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Банкны дугаар</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ settlement.bank_account_number }}
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>SMS тоо</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              {{ settlement.sms_count }}
            </span>
          </div>
        </div>
        <div style="width:144px; display:flex; flex-direction:column; border: 1px solid #F8F8F8;">
          <div style="background-color:#F8F8F8; font-size:10px; width: 95%; height:50px; display:flex; color:#808080; align-items: center; padding-left:5%;">
            <span>Тайлбар</span>
          </div>
          <div style="height:50px; padding-left:5%; display:flex; font-size:12px; font-weight:bold; color:#3A3A3C; align-items:center;">
            <span>
              <el-button v-if="settlement.is_declined_by_client === true" type="info" size="mini" @click="getPartnerSettlementDescription(settlement)">Тайлбар</el-button>
            </span>
          </div>
        </div>
        <div @click="handleSettlement(settlement)" style="cursor: pointer; margin-top:1%; margin-left: 1%; margin-right:2%; background-color:#F8F8F8; height: 32px; width:97%; border-radius:5px; display:flex; justify-content:center; align-items:center;">
          <i :class="settlement.isCollapse ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
        </div>
        <div v-if="settlement.isCollapse" style="margin-left:1%; margin-right:2%; margin-top:1%; width: 100%">
          <el-table
            :data="daysData"
            style="width: 100%; font-size:1.2vh;"
            @row-click="detailSettlementHistory"
            height="350px"
            border
          >
            <el-table-column label="Огноо">
              <template slot-scope="prop">
                <span style="font-size:1.4vh;">{{ $helpers.formatDate(prop.row.date, "MM/DD") }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Захиалга" prop="order_count">
              <template slot-scope="prop">
                <span style="font-size:1.4vh;">{{prop.row.order_count}}</span>
              </template>
            </el-table-column>
            <el-table-column label="Меню дүн">
              <template slot-scope="prop">
                <span style="font-size:1.4vh;">{{ $helpers.formatPrice(parseInt(prop.row.total_sales)) }}₮</span>
              </template>
            </el-table-column>
            <el-table-column label="Шимтгэл">
              <template slot-scope="prop">
                <span style="font-size:1.4vh;">{{ $helpers.formatPrice(parseInt(prop.row.net_sales)) }}₮</span>
              </template>
            </el-table-column>
            <el-table-column label="Зөрүүтэй">
              <template slot-scope="prop">
                <span style="font-size:1.4vh; color:red;" v-if="prop.row.isZoruu">Зөрүүтэй</span>
              </template>
            </el-table-column>
            <el-table-column label="Төлөв" width="120px">
              <template
                slot-scope="prop"
                v-if="prop.row.is_canceled === true"
              >
                <span style="font-size:16px; color:red;">
                  Цуцалсан({{
                    prop.row.canceled_count
                  }})
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-divider></el-divider>
      </div>
      <el-pagination
        class="text-right mt10"
        background
        layout="total, prev, pager, next"
        :page-count="pageCount"
        :current-page.sync="currentPage"
        :total="totalCount"
        :page-size="pageSize"
        @current-change="getSettlement(currentOutletId)"
        :page-sizes="[5, 10, 15, 20]"
      >
      </el-pagination>
    </div>
    <el-dialog
      v-if="oneSettlementData"
      :title="$helpers.formatDate(oneSettlementData.date, 'YYYY/MM/DD') + ' өдөр'"
      :visible.sync="settlementIsDialog"
      width="80%"
    >
      <el-table
        style="width: 100%; background-color: #fff"
        :data="oneSettlementData.orders"
        :row-class-name="rowClassName"
      >
        <el-table-column type="expand">
          <div class="p20 pl60 background-light" slot-scope="prop">
            <el-row
              :gutter="20"
              v-for="(item, index) in prop.row.order_items"
              :key="index"
              class="pt10 pb10"
            >
              <el-col :span="12">
                {{ item.name }}
              </el-col>
              <el-col :span="2" class="text-right"> {{ item.count }}ш </el-col>
              <el-col :span="4" class="text-right">
                {{ $helpers.formatPrice(item.price) }}₮
              </el-col>
            </el-row>
            <el-row :gutter="20" class="pt10 pb10">
              <el-col :offset="14" :span="4" class="text-right">
                <span
                  >Нийт: {{ $helpers.formatPrice(prop.row.total_sales) }}₮</span
                >
              </el-col>
            </el-row>
            <el-row>
              <el-tag
                type="danger"
                v-if="prop.row.is_canceled === true && prop.row.reason_type"
                style="margin-bottom: 10px"
                >Цуцлалтын төрөл: {{ prop.row.reason_type }}</el-tag
              >
            </el-row>
            <el-row>
              <el-tag
                type="danger"
                v-if="prop.row.is_canceled === true && prop.row.cancel_reason"
                >Цуцалсан шалтгаан: {{ prop.row.cancel_reason }}</el-tag
              >
            </el-row>
          </div>
        </el-table-column>
        <el-table-column prop="order_number" label="Дугаар"></el-table-column>
        <el-table-column prop="total_sales" width="90" label="Үнийн дүн">
          <template slot-scope="prop">
            {{ prop.row.is_canceled === true ? 0 : $helpers.formatPrice(prop.row.total_sales) }}
          </template>
        </el-table-column>
        <el-table-column prop="net_sales" width="110" label="Шимтгэл дүн">
          <template slot-scope="prop">
            {{ prop.row.net_sales === null || prop.row.is_canceled.is_canceled === true ? 0 : $helpers.formatPrice(prop.row.net_sales) }}
          </template>
        </el-table-column>
        <el-table-column prop="commission" width="100" label="Шимтгэл%"></el-table-column>
        <el-table-column label="Зөрүү">
          <template slot-scope="prop">
            <el-tag v-if="prop.row.isZoruu" size="small" type="danger"
              >Зөрүүтэй</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="Тэмдэглэл" width="150">
          <template slot-scope="prop">
            <span v-if="prop.row.isZoruu" type="danger">
              {{ prop.row.balance_note }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="confirmed_at" width="150" label="Захиалсан цаг" />
        <!-- <el-table-column prop="kitchen_out" label="kitchen_out" /> -->
        <el-table-column prop="menu_avg_time" width="150" align="center" label="Амалсан хугацаа" />
        <!-- <el-table-column prop="driver_in" label="driver_in" /> -->
        <!-- <el-table-column prop="driver_out" label="driver_out" /> -->
        <el-table-column label="Бэлдсэн хугацаа" prop="prepared_time" width="130"/>
        <el-table-column label="Амжсан эсэх" width="110" align="center">
          <template slot-scope="prop">
            <i :class="prop.row.is_amjsan ? 'el-icon-document-checked' : 'el-icon-document-delete'" :style="prop.row.is_amjsan ? 'font-size: 24px;color: green' : 'font-size: 24px;color: red'"></i>
          </template>
        </el-table-column>
        <el-table-column width="150" label="Хүргэлтийн төлбөр" align="center">
          <template slot-scope="prop">
            <span style="font-size:1.4vh;">{{ prop.row.delivery_charge ? $helpers.formatPrice(parseInt(prop.row.delivery_charge)) + '₮' : '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Үйлдэл" width="150">
          <template slot-scope="prop" v-if="prop.row.is_canceled === false">
            <el-button
              v-if="prop.row.isZoruu"
              size="mini"
              type="primary"
              @click="addDifference(prop.row)"
              >Зөрүүгүй болгох</el-button
            >
            <el-button
              v-else
              size="mini"
              type="primary"
              @click="addDifference(prop.row)"
              :disabled="[10, 21, 20].includes(current) ? false : true"
              >Зөрүү тэмдэглэх</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      v-if="currentDayOrders.length > 0 && currentDay"
      :title="$helpers.formatDate(currentDay.date, 'YYYY/MM/DD') + ' өдөр'"
      :visible.sync="orderListDialogVisible"
      width="80%"
    >
      <el-table
        v-loading="detailOrderLoading"
        style="width: 100%; background-color: #fff"
        :data="currentDayOrders"
      >
        <el-table-column type="expand">
          <div class="p20 pl60 background-light" slot-scope="prop">
            <el-row
              :gutter="20"
              v-for="(item, index) in prop.row.order_items"
              :key="index"
              class="pt10 pb10"
            >
              <el-col :span="12">
                {{ item.name }}
              </el-col>
              <el-col :span="2" class="text-right"> {{ item.count }}ш </el-col>
              <el-col :span="4" class="text-right">
                {{ $helpers.formatPrice(item.price) }}₮
              </el-col>
            </el-row>
            <el-row :gutter="20" class="pt10 pb10">
              <el-col :offset="14" :span="4" class="text-right">
                <span
                  >Нийт: {{ $helpers.formatPrice(prop.row.total_sales) }}₮</span
                >
              </el-col>
            </el-row>
            <el-row>
              <el-tag
                type="danger"
                v-if="prop.row.is_canceled === true && prop.row.reason_type"
                style="margin-bottom: 10px"
                >Цуцлалтын төрөл: {{ prop.row.reason_type }}</el-tag
              >
            </el-row>
            <el-row>
              <el-tag
                type="danger"
                v-if="prop.row.is_canceled === true && prop.row.cancel_reason"
                >Цуцалсан шалтгаан: {{ prop.row.cancel_reason }}</el-tag
              >
            </el-row>
          </div>
        </el-table-column>
        <el-table-column prop="order_number" label="Дугаар"></el-table-column>
        <el-table-column prop="total_sales" width="90" label="Үнийн дүн">
          <template slot-scope="prop">
            {{ $helpers.formatPrice(prop.row.total_sales) }}
          </template>
        </el-table-column>
        <el-table-column prop="net_sales" width="110" label="Шимтгэл дүн">
          <template slot-scope="prop">
            {{ prop.row.net_sales === null ? 0 : $helpers.formatPrice(prop.row.net_sales) }}
          </template>
        </el-table-column>
        <el-table-column prop="commission" width="100" label="Шимтгэл%"></el-table-column>
        <el-table-column label="Зөрүү">
          <template slot-scope="prop">
            <el-tag v-if="prop.row.isZoruu" size="small" type="danger"
              >Зөрүүтэй</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="Тэмдэглэл" width="150">
          <template slot-scope="prop">
            <span v-if="prop.row.isZoruu" type="danger">
              {{ prop.row.balance_note }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="confirmed_at" width="150" label="Захиалсан цаг" />
        <!-- <el-table-column prop="kitchen_out" label="kitchen_out" /> -->
        <el-table-column prop="menu_avg_time" width="150" align="center" label="Амалсан хугацаа" />
        <!-- <el-table-column prop="driver_in" label="driver_in" /> -->
        <!-- <el-table-column prop="driver_out" label="driver_out" /> -->
        <el-table-column label="Бэлдсэн хугацаа" prop="prepared_time" width="130"/>
        <el-table-column label="Амжсан эсэх" width="110" align="center">
          <template slot-scope="prop">
            <i :class="prop.row.is_amjsan ? 'el-icon-document-checked' : 'el-icon-document-delete'" :style="prop.row.is_amjsan ? 'font-size: 24px;color: green' : 'font-size: 24px;color: red'"></i>
          </template>
        </el-table-column>
        <el-table-column width="150" label="Хүргэлтийн төлбөр" align="center">
          <template slot-scope="prop">
            <span style="font-size:1.4vh;">{{ prop.row.delivery_charge ? $helpers.formatPrice(parseInt(prop.row.delivery_charge)) + '₮' : '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Үйлдэл" width="150">
          <template slot-scope="prop" v-if="prop.row.is_canceled === false">
            <el-button
              v-if="prop.row.isZoruu"
              size="mini"
              type="primary"
              @click="addDifference(prop.row)"
              >Зөрүүгүй болгох</el-button
            >
            <el-button
              v-else
              size="mini"
              type="primary"
              @click="addDifference(prop.row)"
              :disabled="[10, 21, 20].includes(current) ? false : true"
              >Зөрүү тэмдэглэх</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-drawer
      v-if="currentOrder"
      :title="currentOrder.order_number + ' дугаартай захиалгын зөрүү'"
      :visible.sync="diffirenceDialogVisible"
      v-loading="differenceLoading"
      :size="size"
    >
      <el-form v-model="currentOrder" style="margin:4%;">
        <el-form-item label="Зөрүү тэмдэглэл">
          <el-input
            v-model="currentOrder.balance_note"
            type="textarea"
            :rows="5"
            :disabled="currentOrder.is_balance ? false : true"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="currentOrder.is_balance"
            >Зөрүүтэй захиалга</el-checkbox
          >
        </el-form-item>
      </el-form>
      <div style="display:flex; justify-content:flex-end; margin-right: 4%;">
        <el-button @click="diffirenceDialogVisible = false">Болих</el-button>
        <el-button type="success" @click="saveDifference">Хадгалах</el-button>
      </div>
    </el-drawer>

    <el-dialog
      v-if="confirmOrder"
      title="Та итгэлтэй байна уу?"
      :visible.sync="confirmDialogVisible"
      v-loading="confirmLoading"
      :width="size"
      style="align-items: center; height: 100%; align-items: center"
    >
      <el-row type="flex" justify="end">
        <el-button @click="confirmDialogVisible = false">Үгүй</el-button>
        <el-button type="primary" @click="changeSettlementStatus">
          Тийм
        </el-button>
      </el-row>
    </el-dialog>

    <el-dialog
      v-if="sendSettlementDetail"
      :title="'Нэхэмжлэл илгээх'"
      :visible.sync="invoiceDialogVisible"
      v-loading="invoiceLoading"
      class="dialog"
    >
      <el-row>
        <el-col :span="10">
          <div class="image-upload-container">
            <div class="image-uploader invoice-image">
              <div class="image-placeholder">
                <div class="image-container">
                  <div class="overlay">
                    <el-upload
                      action=""
                      class="avatar-uploader"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :on-change="file => getFile(file)"
                    >
                      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-button
            style="margin-left: 7%"
            size="medium"
            type="info"
            @click="generate"
            >Загвар харах</el-button
          >
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="12">
              <p class="gray-text">Тооцооны дугаар</p>
            </el-col>
            <el-col :span="12">
              <p class="gray-text">{{ sendSettlementDetail.settlement_id }}</p>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <p class="gray-text">Хамрах хугацаа</p>
            </el-col>
            <el-col :span="12">
              <p class="gray-text">{{ sendSettlementDetail.date }}</p>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <p class="gray-text">Борлуулалт</p>
            </el-col>
            <el-col :span="12">
              <p class="gray-text">{{ sendSettlementDetail.total_sales }}</p>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <p class="gray-text">Нэхэмжлэх дүн</p>
            </el-col>
            <el-col :span="12">
              <p class="gray-text">
                {{
                  sendSettlementDetail.total_sales -
                    sendSettlementDetail.net_sales
                }}
              </p>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <p class="gray-text">Захиалгын тоо</p>
            </el-col>
            <el-col :span="12">
              <p class="gray-text">{{ sendSettlementDetail.order_count }}</p>
            </el-col>
          </el-row>
          <!-- <el-row class="mt40">
            <el-col :span="24">
              <el-button type="success" @click="sendSettlement"
                >Илгээх</el-button
              >
              <el-button @click="cancel">Болих</el-button>
            </el-col>
          </el-row> -->
        </el-col>
      </el-row>
    </el-dialog>
    <el-drawer title="Шалтгаан" :visible.sync="dialogVisible" :size="size" v-loading="confirmLoading">
      <el-form ref="form" :model="form" :rules="rules" style="margin:4%;">
        <el-form-item label="Файл">
          <el-upload
            multiple
            :limit="3"
            action=""
            :file-list="hybridData"
            :on-remove="handleRemove"
            :on-change="files => getUpload(files)"
          >
            <el-button size="mini" type="primary">file оруулах</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item prop="description" label="Тайлбар">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="Тайлбар ..."
            v-model="form.description"
          >
          </el-input>
        </el-form-item>
        <el-form-item class="text-right">
          <el-button @click="dialogVisible = false">Буцах</el-button>
          <el-button type="primary" @click="onSubmit('form')">Илгээх</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <el-drawer
      title="Дүн буулгах"
      :visible.sync="dialogVisibleBuulgah"
      :size="size"
      v-loading="confirmLoading"
    >
      <h1 style="margin-left:2%; text-align:center;">Та үнийн дүн буулгахдаа итгэлтэй байна уу</h1>
      <div style="display:flex; justify-content:center;">
        <el-button @click="dialogVisibleBuulgah = false">Үгүй</el-button>
        <el-button type="primary" :disabled="isDisabled" @click="buulgahButton">Тийм</el-button>
      </div>
    </el-drawer>
    <el-dialog title="Тайлбар" :visible.sync="dialogVisibleTailbar" width="35%">
      <div class="seeDesc">
        <el-table :data="seeDescData" >
          <el-table-column prop="description" label="Тайлбар" width="315">
          </el-table-column>
          <el-table-column prop="files" label="Файл" width="315">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.files" :key="index">
                <a
                  style="text-decoration: revert; color: #1E59F8"
                  :href="item.path"
                  target="blank"
                  >{{ item.name }}
                </a>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTailbar = false" type="primary"
          >Болсон</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<style>
@media screen and (min-width: 1250px) {
  .dialog {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1200px) {
  .dialog {
    display: flex;
    width: 100%;
    height: 800px;
  }
}
@media screen and (max-width: 1000px) {
  .dialog {
    display: flex;
    align-items: center;
    width: 1200px;
    height: 100%;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .success-row {
    background: #f9efeb;
  }
</style>

<script>
import { getBase64 } from '../../../helper/custom'
import helpers from '../../../helper/helper'
import pdfMake from 'pdfmake/build/pdfmake'
import axios from 'axios'
import mAxios from '../../../helper/axiosInstance'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { getToken } from '../../../helper/auth'
import titleItem from '../../../components/items/titleItem.vue'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  name: 'Settlement',
  components: {
    titleItem
  },
  watch: {
    '$root.chosenOutlets' (newVal, oldVal) {
      if (newVal.length > 0 && this.outlet) {
        if (!newVal.find((el) => el._id === this.outlet._id)) {
          this.outlet = null
        }
      }
    }
  },
  data () {
    return {
      oneSettlementData: null,
      settlementIsDialog: false,
      size: '',
      outlet: null,
      currentInvoice: null,
      invoiceData: null,
      oneTableData: null,
      daysData: [],
      currentDayOrders: [],
      detailOrderLoading: false,
      dropdownLoading: false,
      loader: false,
      form: {
        description: '',
        files: []
      },
      rules: {
        first_name: [
          {
            required: true,
            message: 'Please select Activity zone',
            trigger: 'change'
          }
        ],
        last_name: [
          {
            required: true,
            message: 'Please select Activity zone',
            trigger: 'change'
          }
        ],
        description: [
          {
            required: true,
            message: 'Please input activity form',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: 'Please input email address',
            trigger: 'blur'
          },
          {
            type: 'email',
            message: 'Please input correct email address',
            trigger: ['blur', 'change']
          }
        ]
      },
      dialogVisibleBuulgah: false,
      oneSettlementDay: null,
      dialogVisibleTailbar: false,
      dialogVisible: false,
      seeDescData: [],
      outlets: [],
      reject: [],
      agree: [],
      imageUrl: '',
      hybridData: [],
      isDisabled: false,
      imageBase64: '',
      settlementLoading: false,
      settlementData: null,
      orderListDialogVisible: false,
      diffirenceDialogVisible: false,
      confirmDialogVisible: false,
      invoiceDialogVisible: false,
      differenceLoading: false,
      confirmLoading: false,
      invoiceLoading: false,
      currentOutletId: null,
      currentDay: null,
      current: null,
      currentOrder: null,
      declineOrder: null,
      confirmOrder: null,
      predictLoading: false,
      id: null,
      predictSettlementData: null,
      data: true,
      dialogImageUrl: '',
      disabled: false,
      buttonVal: false,
      sendSettlementDetail: {},
      requiredTransfer: [],
      payDate: null,
      datetime: null,
      currentPage: null,
      pageCount: null,
      totalCount: null,
      pageSize: null,
      settlementId: null,
      isDetail: false,
      test: null,
      settlementDayData: []
    }
  },
  mounted () {
    if (!this.$root.isAdmin) {
      this.currentOutletId = this.$root.outlet._id
      this.setCurrentOutlet(this.$root.outlet)
    } else {
      if (this.$root.chosenOutlets.length > 0) {
        this.setCurrentOutlet(this.$root.chosenOutlets[0])
        this.currentOutletId = this.$root.chosenOutlets[0]._id
      }
    }
    if (window.innerWidth < 600) {
      this.size = '90%'
    } else if (window.innerWidth > 601 && window.innerWidth < 1200) {
      this.size = '60%'
    } else {
      this.size = '30%'
    }
  },
  methods: {
    rowClassName (data) {
      if (data.row.is_canceled === true) {
        return 'success-row'
      } else {
        return ''
      }
    },
    showOneDayDetail (row) {
      this.oneSettlementData = row
      this.settlementIsDialog = true
    },
    handleSettlement (settlement) {
      if (!settlement.isCollapse) {
        const payload = {
          outlet_id: this.oneTableData.outlet_id,
          start_date: settlement.start_date,
          end_date: settlement.end_date
        }
        // if (this.isDetail && this.daysData.length === 0) {
        this.dropdownLoading = true
        mAxios.post('settlement/getSettlementDayOrderData', payload).then(response => {
          if (response.data.status === 'success') {
            this.daysData = response.data.data
            this.dropdownLoading = false
          } else {
            this.daysData = []
            this.$notify.error({
              title: 'Уучлаарай',
              position: 'bottom-left',
              message: response.data.message,
              duration: 2000
            })
            this.dropdownLoading = false
          }
        })
        // }
      }
      settlement.isCollapse = !settlement.isCollapse
    },
    async setCurrentOutlet (outlet) {
      this.outlet = null
      setTimeout(() => {
        this.outlet = outlet
        this.currentOutletId = outlet._id
        this.getSettlement(outlet._id)
        this.getPredictSettlement(outlet._id)
        this.getWithdrawBalance(outlet._id)
        this.getPartnerInvoice()
      }, 500)
      this.$forceUpdate()
    },
    handleClick () {
      this.$router.push({ name: 'invoice', params: { id: this.currentOutletId } })
    },
    async getPartnerInvoice () {
      const body = {
        outlet_ids: []
      }
      if (this.$root.isAdmin) {
        body.outlet_ids.push(this.currentOutletId)
      } else {
        this.$root.chosenOutlets.forEach(el => {
          body.outlet_ids.push(el._id)
        })
      }
      await mAxios.post('outlet/getPartnerInvoice', body).then(servicesResponse => {
        if (servicesResponse.data.status === 'success') {
          this.invoiceData = servicesResponse.data.data
          this.currentInvoice = servicesResponse.data.data.outlets.find(element => this.currentOutletId === element.outlet_id)
        } else {
          this.$message.error({
            title: 'Алдаа гарлаа',
            message: servicesResponse.data.message,
            type: 'error'
          })
        }
      })
    },
    async getPartnerSettlementDescription (data) {
      const body = {
        settlement_id: data.settlement_id || data.id
      }
      const requestOptions = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getToken()}`
        }
      }
      axios
        .post('https://rest.toktok.mn/client-api-test/no-auth/settlement/getPartnerSettlementDescription', body, requestOptions)
        .then(response => {
          if (response.data.status === 'success') {
            this.dialogVisibleTailbar = true
            this.seeDescData = response.data.data
          } else {
            this.$notify.error({
              title: 'Уучлаарай',
              position: 'bottom-left',
              message: response.data.message,
              duration: 2000
            })
          }
        })
    },
    buulgahButton () {
      const payload = {
        settlement_id: this.id
      }
      this.confirmLoading = true
      this.isDisabled = true
      mAxios.post('settlement/wageWithdraw', payload).then(response => {
        if (response.data.status === 'success') {
          this.$notify.error({
            title: response.data.data.message !== 'Цалин буулгах боломжгүй байна(manage-рүү ороод дансны дугаар болон mail хаягаа бүртгүүлнэ үү)' ? 'Амжилттай' : 'Анхаар',
            position: 'bottom-left',
            message: response.data.data.message,
            duration: 4000
          })
          this.dialogVisibleBuulgah = false
          this.getWithdrawBalance(this.currentOutletId)
          this.confirmLoading = false
          this.isDisabled = false
        } else {
          this.$notify.error({
            title: 'Уучлаарай',
            position: 'bottom-left',
            message: response.data.message,
            duration: 2000
          })
          this.isDisabled = false
          this.confirmLoading = false
        }
      }).catch(() => {
        this.isDisabled = false
        this.confirmLoading = false
      })
    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.confirmLoading = true
          const payload = {
            settlement_id: this.settlementId,
            description: this.form.description,
            files: this.hybridData
          }
          mAxios.post('settlement/createPartnerSettlementDescription', payload).then(response => {
            if (response.data.status === 'success') {
              this.dialogVisible = false
              this.form.description = ''
              this.form.files = []
              this.hybridData = []
              this.getSettlement(this.currentOutletId)
              this.getWithdrawBalance(this.currentOutletId)
              this.$notify.success({
                title: 'Амжилттай',
                message: 'Амжилттай илгээгдлээ',
                duration: 2000
              })
              this.confirmLoading = false
            } else {
              this.$notify.error({
                title: 'Уучлаарай',
                position: 'bottom-left',
                message: response.data.message,
                duration: 2000
              })
              this.confirmLoading = false
            }
          }).catch(() => {
            this.confirmLoading = false
          })
        } else {
          return false
        }
      })
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    handleRemove (file) {
      this.hybridData = this.hybridData.filter(
        element => element.uid !== file.uid
      )
    },
    getUpload (files) {
      getBase64(files.raw).then(res => {
        const tempBody = {
          data: res,
          type: files.raw.type,
          name: files.name,
          url: URL.createObjectURL(files.raw)
        }
        this.hybridData.push(tempBody)
      })
    },
    getFile (file) {
      const isLt2M = file.size / 1024 / 1024 < 1
      if (
        !(
          file.raw.type === 'image/png' ||
          file.raw.type === 'image/jpeg' ||
          file.raw.type === 'image/jpg'
        )
      ) {
        this.$message.error(
          'Зурагны төрөл буруу байна. png эсвэл jpg байх ёстой.'
        )
        this.imageBase64 = ''
      } else if (!isLt2M) {
        this.$message.error('Зурагны хэмжээ ихдээ 1MB байх ёстой!')
        this.imageBase64 = ''
      } else {
        this.imageUrl = URL.createObjectURL(file.raw)
        getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          this.imageBase64 = tempBody
        })
      }
    },
    generate () {
      this.settlementData.forEach(element => {
        this.requiredTransfer = element.total_sales - element.net_sales
        this.payDate = `${helpers.formatDate(
          element.start_date,
          'MM/DD'
        )} - ${helpers.formatDate(element.end_date, 'MM/DD')}`
      })
      this.datetime = `${helpers.formatDate(
        Date.now(),
        'YYYY оны MM сарын DD өдөр'
      )}`
      const docDefinition = {
        content: [
          { text: '"Tok Tok" ХХК', alignment: 'right' },
          {
            style: 'status',
            text: 'Төлбөрийн хүсэлт Ток Ток',
            alignment: 'center'
          },
          { text: this.datetime, alignment: 'right' },
          {
            text: 'Хэнээс /Газрын нэр, албан тушаал, овог нэр/',
            lineHeight: 1.5
          },
          {
            text: 'Ток Ток ХХК  РД6183352',
            style: 'status',
            alignment: 'center',
            decoration: 'underline'
          },
          {
            text: '/тухайн нэхэмжлэлийг хариуцаж байгаа ажилтны нэр/',
            style: 'explain',
            alignment: 'center',
            lineHeight: 1.5
          },
          {
            text:
              'Гүйлгээ гарах дансны дугаар ____________________________________________________'
          },
          {
            text: '/төлбөрийн хүсэлт гаргасан компаний дансны дугаар/',
            style: 'explain',
            alignment: 'center',
            lineHeight: 1.5
          },
          {
            text: 'Хүлээн авагч /Байгууллагын нэр, хувь хүний овог нэр, РД/',
            lineHeight: 1.5
          },
          { text: 'Гүйлгээ орох дансны дугаар:', lineHeight: 1.5 },
          {
            table: {
              widths: [20, 180, 140, 120],
              body: [
                [
                  { text: '№', alignment: 'center' },
                  {
                    text: 'Төлбөрийн зориулалт',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Нэхэмжлэх дүн',
                    style: 'tableHeader',
                    alignment: 'center'
                  },
                  {
                    text: 'Хянасан дүн',
                    style: 'tableHeader',
                    alignment: 'center'
                  }
                ],
                [
                  { text: ' ' },
                  {
                    text: this.sendSettlementDetail.date + ' тооцоо',
                    alignment: 'center'
                  },
                  {
                    text:
                      this.sendSettlementDetail.total_sales -
                      this.sendSettlementDetail.net_sales,
                    alignment: 'center'
                  },
                  { text: ' ' }
                ],
                [{ text: ' ' }, { text: ' ' }, { text: ' ' }, { text: ' ' }],
                [{ text: ' ' }, { text: ' ' }, { text: ' ' }, { text: ' ' }],
                [
                  { text: ' ' },
                  { text: 'Нийт дүн', alignment: 'center' },
                  { text: ' ' },
                  { text: ' ' }
                ]
              ]
            }
          },
          { text: ' ', lineHeight: 1 },
          {
            lineHeight: 1.5,
            text:
              'Хүсэлт гаргасан хүний гарын үсэг ____________________________________________________'
          },
          {
            lineHeight: 1.5,
            text:
              'ДАГЗахирал __________________________                 _____________________________'
          },
          {
            lineHeight: 1.5,
            text:
              'Гүйцэтгэх захирал __________________________                 _____________________________'
          },
          {
            lineHeight: 1.5,
            text:
              'Ерөнхий нягтлан бодогч __________________________                 _____________________________'
          },
          {
            lineHeight: 1.5,
            text:
              'Санхүүгийн менежер __________________________                 _____________________________'
          },
          {
            lineHeight: 1.5,
            text:
              'Холбогдох албаны дарга __________________________                 _____________________________'
          },
          { lineHeight: 1.5, text: 'Харьцах данс:' },
          {
            lineHeight: 1.5,
            text:
              '1. ______________________________________________________________________________',
            style: 'line'
          },
          {
            lineHeight: 1.5,
            text:
              '2. ______________________________________________________________________________',
            style: 'line'
          }
        ],
        styles: {
          status: {
            bold: true
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          explain: {
            fontSize: 8
          },
          line: {
            margin: [50, 0, 0, 0]
          },
          table: {
            lineHeight: 2
          }
        }
      }

      pdfMake.createPdf(docDefinition).print()
    },
    onClickSendSettlement (data) {
      this.sendSettlementDetail.settlement_id = data.settlement_id
      this.sendSettlementDetail.total_sales = data.total_sales
      this.sendSettlementDetail.order_count = data.order_count
      this.sendSettlementDetail.net_sales = data.net_sales
      this.sendSettlementDetail.date = `${helpers.formatDate(
        data.start_date,
        'MM/DD'
      )} - ${helpers.formatDate(data.end_date, 'MM/DD')}`
      this.invoiceDialogVisible = true
    },
    goToSettlementDetail (data) {
      this.$store.commit('addToSettlementSelectedData', data)
      this.$router.push({
        path: 'settlement-detail',
        query: { _id: data.id }
      })
    },
    changeCurrentOutlet (id) {
      this.currentInvoice = this.invoiceData.outlets.find(element => id === element.outlet_id)
      this.getSettlement(id)
      this.getPredictSettlement(id)
      this.getWithdrawBalance(id)
    },
    getPredictSettlement (outletId) {
      if (outletId !== null) {
        const payload = {
          outlet_id: outletId
        }
        this.predictLoading = true
        mAxios
          .post('settlement/predictSettlement ', payload)
          .then(async response => {
            if (response.data.status === 'success') {
              this.predictSettlementData = response.data.data
            } else {
              this.$notify.error({
                title: 'Уучлаарай',
                position: 'bottom-left',
                message: response.data.message,
                duration: 2000
              })
            }
            this.predictLoading = false
          })
      }
    },
    getSettlement (outletId) {
      this.settlementLoading = true
      const payload = {
        outlet_id: outletId,
        page: this.currentPage
      }
      mAxios.post('settlement/getSettlementHistory', payload).then(response => {
        if (response.data.status === 'success') {
          if (response.data.data.success === true) {
            const tempData = JSON.parse(JSON.stringify(response.data.data.data.data))
            tempData.forEach(element => {
              element.isCollapse = false
            })
            this.settlementData = tempData
            this.currentPage = response.data.data.data.current_page
            this.pageCount = response.data.data.data.last_page
            this.totalCount = response.data.data.data.total
            this.pageSize = response.data.data.data.per_page
          } else {
            this.settlementData = []
            this.$notify.error({
              title: 'Уучлаарай',
              position: 'bottom-left',
              message: response.data.message,
              duration: 2000
            })
          }
        } else {
          this.settlementData = []
          this.$notify.error({
            title: 'Уучлаарай',
            position: 'bottom-left',
            message: response.data.message,
            duration: 2000
          })
        }
        this.settlementLoading = false
      })
    },
    getWithdrawBalance (outletId) {
      if (outletId === null) {
        this.$notify.error({
          title: 'Анхааруулга',
          position: 'bottom-left',
          message: 'Харилцагч сонгоно уу',
          duration: 3000
        })
        return false
      }
      const payload = {
        outlet_id: outletId
      }
      this.loader = true
      mAxios.post('settlement/getCurrentSettlement ', payload).then(response => {
        if (response.data.status === 'success' && response.data.data !== null) {
          this.oneTableData = response.data.data
          this.id = response.data.data.settlement_id
          this.loader = false
        } else {
          this.oneTableData = {}
          this.$notify.error({
            title: 'Уучлаарай',
            position: 'bottom-left',
            message: response.data.message,
            duration: 2000
          })
          this.loader = false
        }
      })
    },
    cancel () {
      this.imageUrl = null
      this.invoiceDialogVisible = false
    },
    addDifference (order) {
      this.diffirenceDialogVisible = true
      this.currentOrder = order
    },
    confirm (order, statusCode) {
      this.confirmDialogVisible = true
      this.confirmOrder = order
      this.saveSettlementStatus = statusCode
    },
    unconfirm (order) {
      this.dialogVisible = true
      this.settlementId = order.settlement_id
    },
    changeSettlementStatus () {
      const payload = {
        settlement_id: this.confirmOrder.settlement_id,
        from: 'client',
        outlet_id: this.confirmOrder.outlet_id
      }
      this.confirmLoading = true
      mAxios.post('settlement/acceptSettlement', payload)
        .then(async response => {
          if (response.data.status === 'success') {
            this.$notify.success({
              title: 'Амжилттай',
              position: 'bottom-left',
              message: ' Амжилттай',
              duration: 2000
            })
            this.confirmLoading = false
            this.confirmDialogVisible = false
            this.getSettlement(this.currentOutletId)
            this.getWithdrawBalance(this.currentOutletId)
          } else {
            this.$notify.error({
              title: 'Уучлаарай',
              position: 'bottom-left',
              message: response.data.message,
              duration: 2000
            })
            this.confirmLoading = false
          }
        })
      this.confirmDialogVisible = false
    },
    detailSettlement () {
      this.isDetail = !this.isDetail
      const payload = {
        outlet_id: this.oneTableData.outlet_id,
        start_date: this.oneTableData.start_date,
        end_date: this.oneTableData.end_date
      }
      if (this.isDetail && this.daysData.length === 0) {
        this.dropdownLoading = true
        mAxios.post('settlement/getSettlementDayOrderData', payload).then(response => {
          if (response.data.status === 'success') {
            this.daysData = response.data.data
            this.dropdownLoading = false
          } else {
            this.daysData = []
            this.$notify.error({
              title: 'Уучлаарай',
              position: 'bottom-left',
              message: response.data.message,
              duration: 2000
            })
            this.dropdownLoading = false
          }
        })
      }
    },
    detailSettlementHistory (data) {
      this.currentDay = data
      const payload = {
        outlet_id: this.oneTableData.outlet_id,
        date: data.date
      }
      this.orderListDialogVisible = true
      this.dropdownLoading = true
      mAxios.post('settlement/getSettlementDayOrderDetail', payload).then(response => {
        if (response.data.status === 'success') {
          this.currentDayOrders = response.data.data
          this.dropdownLoading = false
        } else {
          this.daysData = []
          this.$notify.error({
            title: 'Уучлаарай',
            position: 'bottom-left',
            message: response.data.message,
            duration: 2000
          })
          this.dropdownLoading = false
        }
      })
      this.settlementData.forEach(element => {
        this.current = element.status_code
      })
    },
    showDayDetail (row) {
      this.currentDay = row
      this.orderListDialogVisible = true
      this.detailOrderLoading = true
      const payload = {
        outlet_id: this.oneTableData.outlet_id,
        date: row.date
      }
      mAxios.post('settlement/getSettlementDayOrderDetail ', payload).then(response => {
        if (response.data.status === 'success') {
          this.currentDayOrders = response.data.data
          this.detailOrderLoading = false
        } else {
          this.currentDayOrders = []
          this.$notify.error({
            title: 'Уучлаарай',
            position: 'bottom-left',
            message: response.data.message,
            duration: 2000
          })
          this.detailOrderLoading = false
        }
      })
      this.settlementData.forEach(element => {
        this.current = element.status_code
      })
    }
  }
}
</script>
<style>
  .seeDesc .el-table td {
    cursor: default !important;
  }
</style>
